
import {
  reactive,
  toRefs,
  computed,
  watch,
  inject,
} from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'

import { getBoolingListApi } from "@/api/order"
import { 
  defaultError,
} from '@/utils/common.js'

import dayjs from 'dayjs'

import ScrollLoadWrap from "@/components/ScrollLoadWrap"

export default {
  name: 'History',
  components: {
    ScrollLoadWrap,
  },
  setup() {
    const state = reactive({
      currentTab: 'all',
      orderList: [],
      pageSize: 10,
      totalCount: 0,
    })
    
    const store = useStore()
    const router = useRouter()
    const toast = inject('toast')
    const loading = inject('loading')

    const expressFee = computed(() => store.state.expressFee)

    // 获取订单列表
    watch(
      () => store.state.code, 
      (newCode) => {
        if(!newCode) return
        getBoolingList()
      },
      { immediate: true }
    )
    async function getBoolingList() {
      let bookingStatus = -1
      if(state.currentTab === 'deliver') bookingStatus = 0
      if(state.currentTab === 'receive') bookingStatus = 10

      loading.show()
      const res = await getBoolingListApi({
        activityCode: store.state.code,
        limit: state.pageSize,
        offset: state.orderList.length,
        bookingStatus,
      })
      loading.hide()

      if(res.code === 0) {
        state.orderList = state.orderList.concat(formatBookingList(res.data.bookings))
        state.totalCount = res.data.totalCount
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }
    function formatBookingList(bookings) {
      return bookings.map( x => {
        x.statusStr = '已发货'
        if(x.bookingStatus === 0) x.statusStr = '待发货'

        x.giftCount = x.giftReceiveItemViewModels.length

        x.date = dayjs(x.createDate).format('YYYY-MM-DD')

        let bookingPrice = 0
        x.giftReceiveItemViewModels = x.giftReceiveItemViewModels.map( giftItem => {
          giftItem.specString = `${giftItem.attributeValue1} ${giftItem.attributeValue2}`
          bookingPrice += giftItem.giftTotalPrice

          return giftItem
        })

        x.bookingPrice = bookingPrice + expressFee.value

        return x
      })
    }

    const scrollLoad = () => {
      if(state.orderList.length >= state.totalCount) return
      getBoolingList()
    }

    const toggleTab = (e) => {
      const name = e.srcElement.dataset.name
      if(name === state.currentTab) return

      state.currentTab = name
      state.orderList = []
      getBoolingList()
    }

    const toDetail = (index) => {
      const bookingId = state.orderList[index].bookingId
      router.push({ name: 'OrderDetail', params: { bookingId } })
    }

    return {
      ...toRefs(state),
      scrollLoad,
      toggleTab,
      toDetail,
      expressFee,
    }
  },
} 