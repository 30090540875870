<template>
  <div class="history">
    <div class="history-tab">
      <div 
        @click="toggleTab"
        data-name="all"
        class="tab-item"
        :class="{active: currentTab==='all'}">全部</div>
      <div 
        @click="toggleTab"
        data-name="deliver"
        class="tab-item"
        :class="{active: currentTab==='deliver'}">待发货</div>
      <div 
        @click="toggleTab"
        data-name="receive"
        class="tab-item"
        :class="{active: currentTab==='receive'}">待收货</div>
    </div>

    <div class="history-content">
      <div class="history-scroll">
        <scroll-load-wrap @scroll-load="scrollLoad">
          <div class="booking">
            <div 
              v-for="(item, index) in orderList"
              :key="item.bookingId"
              @click="toDetail(index)"
              class="booking-item">
              <div class="booking-header">
                <div class="booking-statusBox">
                  <div class="booking-status">{{item.statusStr}}</div>
                  <div v-if="item.courierCount>0" class="booking-txt1">共 {{item.courierCount}} 个快递单号</div>
                </div>

                <div class="booking-txt1">{{item.date}}</div>
              </div>

              <div class="booking-content">
                <div 
                  v-for="giftItem in item.giftReceiveItemViewModels"
                  :key="giftItem.id"
                  class="order-item">
                  <div class="order-imgBox">
                    <img :src="giftItem.giftImage" class="order-img">
                  </div>

                  <div class="order-content">
                    <div class="order-caption">
                      <div class="order-name">{{giftItem.giftName}}</div>
                      <div class="order-params">{{giftItem.specString}} X{{giftItem.giftCount}}</div>
                    </div>

                    <div class="order-points">
                      <div class="order-txt1">{{giftItem.giftTotalPrice}}</div>
                      <div class="order-txt2">积分</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="expressFee">
                <div class="expressFee-title">运费：</div>
                <div class="expressFee-content">
                  <span class="expressFee-points">{{expressFee}}</span> 积分
                </div>
              </div>

              <div class="booking-footer">
                <div class="booking-num">订单号：{{item.bookingId}}</div>
                <div class="booking-summary">
                  <div class="booking-txt2">合计：</div>
                  <div class="booking-txt3">{{item.bookingPrice}}</div>
                  <div class="booking-txt4">积分</div>
                </div>
              </div>
            </div>
          </div>
        </scroll-load-wrap>
      </div>
    </div>
  </div>
</template>

<script src='./History.js'></script>

<style scoped lang="scss" src="./History.scss"></style>